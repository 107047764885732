import { render, staticRenderFns } from "./DeliveryInfoModal.vue?vue&type=template&id=7675f896"
import script from "./DeliveryInfoModal.vue?vue&type=script&lang=js"
export * from "./DeliveryInfoModal.vue?vue&type=script&lang=js"
import style0 from "./DeliveryInfoModal.vue?vue&type=style&index=0&id=7675f896&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QImg,QIcon});qInstall(component, 'directives', {ClosePopup});
