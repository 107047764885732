<template>
  <q-dialog
    v-if="currentLocationDetail"
    v-model="dialogState"
    @show="dialogOpened"
    :maximized="isMobile"
    :position="isMobile ? 'right' : 'standard'"
    :transition-show="isMobile ? 'slide-left' : 'jump-up'"
    :transition-hide="isMobile ? 'slide-right' : 'jump-down'"
  >
    <q-card class="modalDeliveryInfo" style="width: 800px; max-width: 100vw">
      <q-card-section class="row items-center q-pa-sm">
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="no-padding">
        <q-tabs
          no-caps
          v-model="tab"
          class="text-grey"
          active-color="black"
          indicator-color="secondaryOnBody"
          align="justify"
        >
          <q-tab
            v-for="(fulfillment, key) of availableFulFillments"
            :key="`${fulfillment.codeName}-${key}`"
            :name="fulfillment.codeName"
          >
            <!-- ToDo: v-show Temporary -->
            <SvgIcon
              size="1.5rem"
              color="secondaryOnBody"
              :icon="fulfillment.codeName"
            />
            <template v-if="!isMobile">
              {{ fulfillment.fulfillmentName || fulfillment.displayName }}
            </template>
          </q-tab>
        </q-tabs>

        <q-separator />

        <q-tab-panels
          swipeable
          v-model="tab"
          animated
          transition-prev="fade"
          transition-next="fade"
        >
          <q-tab-panel
            v-for="(fulfillment, key) of availableFulFillments"
            :key="`${fulfillment.codeName}-${key}`"
            :name="fulfillment.codeName"
          >
            <!-- ToDo: v-show Temporary -->
            <div class="row items-start">
              <div class="col-12 col-md-5 q-pr-lg">
                <div
                  v-if="
                    fulfillment.infoForCustomer ||
                    fulfillment.configuration?.infoForCustomer
                  "
                  v-html="
                    fulfillment.infoForCustomer ||
                    fulfillment.configuration?.infoForCustomer ||
                    ''
                  "
                />
                <p
                  class="link-bottom"
                  v-if="
                    detail[fulfillment.codeName].checkAvailabilityButton &&
                    checkSlotBookingRequired(fulfillment.codeName)
                  "
                >
                  <q-btn
                    unelevated
                    no-caps
                    rounded
                    color="secondaryOnBody"
                    @click="openReserveSlot(fulfillment.codeName)"
                    :label="`Check Availability`"
                    class="q-mt-md"
                  />
                </p>
              </div>
              <div class="col-12 offset-md-1 col-md-6 text-center">
                <q-img
                  :src="detail[fulfillment.codeName].image"
                  spinner-color="white"
                  class="thumbside"
                />
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('deliveryInfo')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'deliveryInfo',
          val,
        })
      },
    },
    ...mapGetters('location', ['currentLocationDetail']),
    ...mapGetters('common', ['checkSlotBookingRequired']),
    availableFulFillments() {
      let fulFillments = Object.values(
          this.currentLocationDetail?.fulFillments || []
        ),
        result = []

      for (let fulfillment of fulFillments) {
        if (fulfillment.available && fulfillment.codeName !== 'sd')
          result.push(fulfillment)
      }

      if (this.businessFulfillments['sd']?.isActive)
        result.push(this.businessFulfillments['sd'])

      return result
    },
  },
  data() {
    return {
      tab: 'scd',
      detail: {
        scd: {
          image: 'images/delivery-scd.png',
          checkAvailabilityButton: true,
        },
        sp: {
          image: 'images/delivery-sp.png',
          checkAvailabilityButton: true,
        },
        sd: {
          image: 'images/delivery-sd.png',
          checkAvailabilityButton: false,
        },
      },
    }
  },
  methods: {
    openReserveSlot(bookingType) {
      let openDialog = () => {
        this.changeDialogState({
          dialog: 'reserveTimeslot',
          val: true,
          properties: {
            activeTab: bookingType.toUpperCase(),
          },
        })
      }

      openDialog()
    },
    dialogOpened() {
      this.tab = this.availableFulFillments[0]['codeName']
    },
  },
  created() {
    this.$root.$on('selected-delivery-info', async (data) => {
      if (this.detail[data]) {
        this.tab = data
      }
    })
  },
}
</script>
<style lang="scss">
.modalDeliveryInfo {
  border-radius: 20px !important;

  .q-tabs {
    margin: 0 15px;
    color: #666666;
    & + .q-separator {
      width: calc(100% - 30px);
      margin: 0 auto;
    }
    .q-tab {
      min-height: 66px;
      font-size: 14px;
      .q-icon {
        margin-bottom: 5px;
        visibility: visible;
        opacity: 1;
        fill: $secondary;
        transition: all 0.3s ease-in-out;
        @media (max-width: 767px) {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    /*.q-tab--active {
      &.q-tab {
        .q-icon {
          visibility: visible;
          opacity: 1;
        }
      }
    }*/
    @media (min-width: 1024px) {
      margin: 0 40px;
      & + .q-separator {
        width: calc(100% - 80px);
        margin: 0 auto;
      }
      .q-tab {
        min-height: 76px;
        font-size: 18px;
      }
    }
  }
  .q-panel {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(/images/bg-deliveryinfo.png) right bottom no-repeat;
      background-size: 100%;
    }
  }
  .q-tab-panel {
    position: relative;
    z-index: 1;
    min-height: 400px;
    padding: 15px 20px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
      margin-bottom: 15px;
      padding: 0;
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      font-family: 'Poppins', sans-serif;
      color: #393939;
    }
    p {
      margin: 0;
      margin-bottom: 15px;
      padding: 0;
      font-size: 15px;
      line-height: 22px;
      color: #666666;
    }
    .thumbside {
    }
    @media (min-width: 1024px) {
      padding: 40px 50px;
      padding-bottom: 80px;
      h3 {
        font-size: 30px;
        line-height: 32px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
      .link-bottom {
        position: absolute;
        bottom: 20px;
      }
      .thumbside {
        width: 100%;
        height: 280px;
      }
    }
    @media (min-width: 1681px) {
      padding: 40px 50px;
      padding-bottom: 80px;
      h3 {
        font-size: 34px;
        line-height: 42px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
      }
      .link-bottom {
        position: absolute;
        bottom: 20px;
      }
    }
  }
}
</style>
